<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t('common.setting.security') }}</div>
      <!-- 登陆密码 -->
      <div class="items" v-if="isSuperAdmin ? isSuperAdmin : !isEnterpriseChildUser ? true : false">
        <div>
          <el-alert :closable="false" :title="$t('common.password.login')" type="success" show-icon>
          </el-alert>
          <div class="text">
            {{ $t('other.text.security.password.desc') }}
          </div>
        </div>
        <div>
          <el-button link type="primary" @click="changePassword"
          >{{ $t('common.update') }}
          </el-button
          >
        </div>
      </div>
      <!-- 手机绑定 -->
      <div class="items">
        <div v-if="userInfo.phone===''|| userInfo.phone===null">
          <el-alert :closable="false" :title="'['+$t('common.as16')+']'" type="error" show-icon>
          </el-alert>
          <div class="text">
            {{ $t('common.as16') }}
          </div>
        </div>
        <div v-else>
          <el-alert :closable="false" :title="$t('common.binding.phone')" type="success" show-icon>
          </el-alert>
          <div class="text">
            {{ $t('common.ph34', {phone: userInfo.phone}) }}
          </div>
        </div>
        <div v-if="userInfo.phone===''||userInfo.phone===null">
          <el-button link type="primary" @click="bindPhoneTo">{{ $t("common.setting.now") }}</el-button>
        </div>
        <div v-else>
          <el-button link type="primary" @click="unbindPhoneTo">{{ $t("common.unbind") }}</el-button>
        </div>
      </div>
      <!-- 邮箱绑定 -->
      <div class="items">
        <div v-if="userInfo.email===''||userInfo.email===null">
          <el-alert
              :closable="false"
              :title="'['+$t('common.setting.none')+']'"
              type="error"
              show-icon
          >
          </el-alert>
          <div class="text">
            {{ $t("other.text.security.email.desc") }}
          </div>
        </div>
        <div v-else>
          <el-alert :closable="false" :title="$t('common.binding.emailbox')" type="success" show-icon>
          </el-alert>
          <div class="text">
            {{ $t("other.text.security.email.desc") }}
          </div>
        </div>
        <div v-if="userInfo.email===''|| userInfo.email===null">
          <el-button link type="primary" @click="bindEmailTo">{{ $t("common.setting.now") }}</el-button>
        </div>
        <div v-else>
          <el-button link type="primary" @click="unbindEmailTo">{{ $t("common.unbind") }}</el-button>
        </div>
      </div>
      <!-- 选择语言 -->
      <div class="items">
        <div>
          <el-alert :closable="false" :title="$t('common.lang.select')" type="success" show-icon>
          </el-alert>
          <div class="selects">
            <el-select v-model="langLocal" @change="changeLang">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.key"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <!--      注销-->
      <div class="items" v-if="isSuperAdmin ? isSuperAdmin : !isEnterpriseChildUser ? true : false">
        <div>
          <el-alert
              :closable="false"
              :title="'注销账号'"
              type="warning"
              show-icon
          >
          </el-alert>
          <div class="text">
            账号注销后，将会删除账号的全部数据，
            无法找回，请谨慎操作
          </div>
        </div>
        <el-button link type="danger" @click="Logout">立即注销</el-button>
      </div>
    </div>
    <!-- 修改密码 -->
    <Dialog
        v-model="dialogVisible"
        :title="$t('common.reset.password')"
        :dialogVisible="dialogVisible"
        :isHeadLine="true"
        @update:dialogVisible="(val) => (dialogVisible = val)"
    >
      <template #content>
        <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="rules"
            class="demo-ruleForm"
            :label-position="'top'"
            style="padding:0px 20px"
        >
          <el-form-item :label="$t('40021')" prop="password">
            <el-input type="password" v-model="ruleForm.password" show-password>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('sys.tip.input.newpassword')" prop="pass">
            <el-input
                v-model="ruleForm.pass"
                type="password"
                autocomplete="off"
                show-password
            />
          </el-form-item>
          <el-form-item :label="$t('sys.tip.input.password.again')" prop="checkPass">
            <el-input
                v-model="ruleForm.checkPass"
                type="password"
                autocomplete="off"
                show-password
            />
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="bottom_btn">
          <el-button @click="cancelClick">{{ $t('common.cancel') }}</el-button>
          <el-button type="primary" @click="submit"
          >{{ $t("common.confirm") }}
          </el-button
          >
        </div>
      </template>
    </Dialog>
    <!-- 手机绑定-->
    <Dialog
        v-model="dialogVisiblePhone"
        :title="$t('common.binding.phone')"
        :dialogVisible="dialogVisiblePhone"
        :isHeadLine="true"
        :width="'450'"
        @update:dialogVisible="(val) => (dialogVisiblePhone = val)"
    >
      <template #content>
        <el-form
            ref="ruleFormRefPhone"
            :model="ruleFormPhone"
            :rules="rulesPhone"
            class="demo-ruleForm"
            :label-position="'top'"
            style="padding:0px 20px"
        >
          <el-form-item :label="$t('common.phone.number')" prop="phone">
            <CountrySelect @changeSelect="getSelectValue"></CountrySelect>
            <el-input
                style="width: calc(100% - 95px)"
                v-model="ruleFormPhone.phone"
                :placeholder="$t('sys.tip.input.phone')"
                :disabled="bindPhoneValue!==''"
            ></el-input>
          </el-form-item>
          <el-form-item
              :label="$t('common.yzm')"
              prop="emailCode"
              :aria-placeholder="$t('sys.tip.input.sms.code')"
              id="sp_input"
          >
            <el-input
                v-model="ruleFormPhone.code"
                :placeholder="$t('90104')"
                :clearable="false"
                @keydown.enter.native="bindPhoneApis"
            >
              <template #suffix>
                <el-button type="primary" @click="sendPhone" :disabled="!showPhone" link
                           style="padding-right:15px;font-size: 12px">
                  <span v-if="showPhone">{{ $t('common.label.getsmscode') }}</span>
                  <span v-show="!showPhone">{{ countPhone }}s</span>
                </el-button>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="bottom_btn">
          <el-button @click="cancelPhone">{{ $t('common.sss16') }}</el-button>
          <el-button type="primary" @click="bindPhoneApis"
          >{{ $t("common.confirm") }}
          </el-button
          >
        </div>
      </template>
    </Dialog>
    <!-- 邮箱绑定 -->
    <Dialog
        v-model="dialogVisibleEmail"
        :title="$t('common.binding.emailbox')"
        :dialogVisible="dialogVisibleEmail"
        :isHeadLine="true"
        @update:dialogVisible="(val) => (dialogVisibleEmail = val)"
        style="padding:0px 20px"
        :width="'450'"
    >
      <template #content>
        <el-form
            ref="ruleFormRefEmail"
            :model="ruleFormEmail"
            :rules="rulesEmail"
            :label-position="'top'"
        >
          <el-form-item :label="$t('common.emailbox')" prop="email">
            <el-input
                :disabled="bindEmailValue!==''"
                v-model="ruleFormEmail.email"
                :placeholder="$t('sys.tip.input.emailbox')"
            ></el-input>
          </el-form-item>
          <el-form-item
              :label="$t('common.yzm')"
              prop="emailCode"
              :aria-placeholder="$t('90104')"
              id="sp_input"
          >
            <el-input
                v-model="ruleFormEmail.emailCode"
                :placeholder="$t('90104')"
                :clearable="false"
                @keydown.enter.native="bindEmailApis"
            >
              <template #suffix>
                <el-button type="primary" @click="send" :disabled="!show" link
                           style="padding-right: 15px;font-size: 12px">
                  <span v-if="show">{{ $t('common.label.getsmscode') }}</span>
                  <span v-show="!show">{{ count }}s</span>
                </el-button>
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <div class="bottom_btn">
          <el-button @click="cancelEmail">{{ $t('common.sss16') }}</el-button>
          <el-button type="primary" @click="bindEmailApis">{{ $t("common.confirm") }}</el-button>
        </div>
      </template>
    </Dialog>

    <LoginOutDia v-if="visitable" v-model="visitable" @close="close"></LoginOutDia>
  </div>
</template>

<script setup>
import {ref, reactive, onMounted, computed} from "vue";
import {TIME_COUNTPhone, TIME_COUNT, emailReg, phoneReg, passwordReg} from "@/utils/statusParams";
import Dialog from "@/components/Dialog.vue";
import {ElMessage} from "element-plus";
import {sendEmailSms, bindEmail, sendBindPhoneSms, bindPhone, updatePassword} from "@/api/usercenter";
import {useStore} from "vuex";
import CountrySelect from "@/components/CountrySelect/index.vue";
import {i18n} from "@/lang";
import LoginOutDia from "@/components/DiaPops/LoginOutDia.vue";
import ImportExcel from "@/components/DiaPops/ImportExcel.vue";

const store = useStore()
// 判断是否是超级管理员如果是的话 就展示
const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])
const isEnterpriseChildUser = computed(
    () => store.getters["user/isEnterpriseChildUser"]
);

const options = ref([
  {key: '简体中文', value: 'zh-CN'}, // zh_CN 简体中文
  {key: 'English', value: 'en'} // en English
]);
const langLocal = computed(() => store.state.system.langLocal)
// 改变语言
const changeLang = (e) => {
  console.log(e, '切换语言')
  store.commit('system/changeLanguage', e)
  let timer = setTimeout(() => {
    location.reload()
    clearTimeout(timer)
  }, 150)
};

// 选中的区号 默认为中国
const selectTargetPrefix = ref('CN')
const getSelectValue = (val) => {
  selectTargetPrefix.value = val.zoneCode
}

// 绑定的邮箱
const bindEmailValue = ref('')
// 绑定的手机
const bindPhoneValue = ref('')
const userInfo = computed(() => store.state.user.userInfo)
// 修改密码事件
const dialogVisible = ref(false);
const ruleForm = ref([{password: "", pass: "", checkPass: ""}]);
const ruleFormPhone = ref({})

const rulesPhone = reactive({
  phone: [{required: true, message: i18n.global.t('sys.tip.input.phone'), trigger: "blur"}],
  code: [{required: true, message: i18n.global.t('sys.tip.input.verify.code'), trigger: "blur"}]
})
const changePassword = () => {
  dialogVisible.value = true;
};

// 表单
const ruleFormRef = ref();
const ruleFormRefEmail = ref();
const ruleFormRefPhone = ref();
// 校验
const validatePass = (rule, value, callback) => {
  if (passwordReg.test(value)) {
    if (value === "") {
      callback(new Error(i18n.global.t('sys.tip.input.password.new')));
    } else {
      if (ruleForm.value.checkPass !== "") {
        if (!ruleFormRef.value) return;
        ruleFormRef.value.validateField("checkPass", () => null);
      }
      callback();
    }
  } else {
    callback(new Error(i18n.global.t("common.password.text")))
  }
};
const validatePass2 = (rule, value, callback) => {
  if (passwordReg.test(value)) {
    if (value === "") {
      callback(new Error(i18n.global.t('40004')));
    } else if (value !== ruleForm.value.pass) {
      callback(new Error(i18n.global.t('40005')));
    } else {
      callback();
    }
  } else {
    callback(new Error(i18n.global.t("common.password.text")))
  }
};

const rules = reactive({
  password: [{required: true, message: i18n.global.t('sys.tip.input.password'), trigger: "blur"}],
  pass: [{validator: validatePass, trigger: "blur"}],
  checkPass: [{validator: validatePass2, trigger: "blur"}],
});
const rulesEmail = reactive({
  email: [{required: true, message: i18n.global.t('sys.tip.input.emailbox'), trigger: "blur"}],
  emailCode: [{required: true, message: i18n.global.t('sys.tip.input.verify.code'), trigger: "blur"}],
});

const submit = () => {
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      const res = await updatePassword({
        oldPassword: ruleForm.value.password,
        newPassword: ruleForm.value.pass,
        submitPassword: ruleForm.value.checkPass
      })
      if (res.code !== 200) {
        ElMessage.error(res.message)
        return
      }
      ElMessage.success(res.message)
      await store.dispatch('user/getUserInfoForGUID')
      dialogVisible.value = false;
      ruleFormRef.value.resetFields();
    } else {
      console.log("error submit!!");
      return false;
    }
  });
};

const cancelClick = () => {
  dialogVisible.value = false;
  ruleFormRef.value.resetFields();
};

const cancelEmail = () => {
  dialogVisibleEmail.value = false;
  ruleFormRefEmail.value.resetFields();
};
const cancelPhone = () => {
  dialogVisiblePhone.value = false;
  ruleFormRefPhone.value.resetFields();
}
//邮箱
// 邮箱
const dialogVisibleEmail = ref(false);
const ruleFormEmail = ref({email: "", emailCode: ""})
const bindEmailTo = () => {
  ruleFormEmail.value.email = ''
  ruleFormEmail.value.emailCode = ''
  dialogVisibleEmail.value = true;
};
const unbindEmailTo = async () => {
  ruleFormEmail.value.email = bindEmailValue.value
  ruleFormEmail.value.emailCode = ''
  dialogVisibleEmail.value = true;
}

const dialogVisiblePhone = ref(false)
// 手机绑定
const bindPhoneTo = () => {
  ruleFormPhone.value.code = ''
  ruleFormPhone.value.phone = ''
  dialogVisiblePhone.value = true
}
const unbindPhoneTo = async () => {
  ruleFormPhone.value.phone = bindPhoneValue.value
  ruleFormPhone.value.code = ''
  dialogVisiblePhone.value = true
}

/**
 * 绑定邮箱
 * @type {null}
 */
const timer = ref(null);
const count = ref(0);
const show = ref(true)
const send = () => {
  if (ruleFormEmail.value.email !== '' && emailReg.test(ruleFormEmail.value.email)) {
    if (!timer.value) {
      count.value = TIME_COUNT;
      show.value = false;
      timer.value = setInterval(() => {
        if (count.value > 0 && count.value <= TIME_COUNT) {
          count.value--;
        } else {
          show.value = true;
          clearInterval(timer.value); // 清除定时器
          timer.value = null;
        }
      }, 1000)

      //   发送请求
      sendEmailSmsApis()
    }
  } else {
    ElMessage.error(i18n.global.t('sys.message.error.emailbox'));
  }
}
// 发送邮箱验证码
const sendEmailSmsApis = async () => {
  const res = await sendEmailSms({
    email: ruleFormEmail.value.email
  })
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  ElMessage.success(res.message)
}
// 绑定邮箱，重新获取用户数据
const bindEmailApis = async () => {
  const res = await bindEmail({
    email: ruleFormEmail.value.email,
    code: ruleFormEmail.value.emailCode,
    type: bindEmailValue.value !== '' ? 2 : 1
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)
  dialogVisibleEmail.value = false;
//   重新获取用户相关数据
  await store.dispatch('user/getUserInfoForGUID')
  await getBindEmails()
  ruleFormRefEmail.value.resetFields();
}

// 绑定手机号
const timerPhone = ref(null);
const countPhone = ref(0);
const showPhone = ref(true)
const sendPhone = () => {
  if (ruleFormPhone.value.phone !== '' && phoneReg.test(ruleFormPhone.value.phone)) {
    if (!timerPhone.value) {
      countPhone.value = TIME_COUNTPhone;
      showPhone.value = false;
      timerPhone.value = setInterval(() => {
        if (countPhone.value > 0 && countPhone.value <= TIME_COUNTPhone) {
          countPhone.value--;
        } else {
          showPhone.value = true;
          clearInterval(timerPhone.value); // 清除定时器
          timerPhone.value = null;
        }
      }, 1000)
      //   发送请求
      sendBindPhoneSmsApis()
    }
  } else {
    ElMessage.error($t('sys.message.error.phone'));
  }
}
// 发送手机验证码
const sendBindPhoneSmsApis = async () => {
  const res = await sendBindPhoneSms({
    phone: ruleFormPhone.value.phone,
    zoneCode: selectTargetPrefix.value
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)

}

// 绑定手机
const bindPhoneApis = async () => {
  const res = await bindPhone({
    phone: ruleFormPhone.value.phone,
    code: ruleFormPhone.value.code,
    type: bindPhoneValue.value !== '' ? 2 : 1,
    zoneCode: selectTargetPrefix.value
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)
  dialogVisiblePhone.value = false
  await store.dispatch('user/getUserInfoForGUID')
  await getBindPhones()
  ruleFormRefPhone.value.resetFields()
}

// 重新获取用户信息
const getBindEmails = () => {
  const email = JSON.parse(localStorage.getItem('userInfo')).email
  if (email) {
    bindEmailValue.value = email
  }
}
// 重新获取用户信息
const getBindPhones = () => {
  const phone = JSON.parse(localStorage.getItem('userInfo')).phone
  if (phone) {
    bindPhoneValue.value = phone
  }
}


onMounted(async () => {
  await getBindEmails()
  await getBindPhones()
})

/**
 * 账号注销
 */
const visitable = ref(false);
const Logout = () => {
  visitable.value = true
}
const close = () => {
  visitable.value = false;
}

</script>

<style lang="less" scoped>
@import "./less/Tables.less";

.inners {
  width: 1000px !important;
  min-width: 1000px !important;
}

:deep(.el-alert) {
  background: white !important;
  padding: 0px 0px !important;
}

.items {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .text {
    font-size: 14px;
    color: #999;
    margin: 10px 0 20px 18px;
  }
}

:deep(.el-alert__title) {
  font-size: 14px !important;
}

.selects {
  width: 250px;
  margin: 10px 0 20px 18px;

  :deep(.el-select) {
    width: 100% !important;
  }
}

.bottom_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

#sp_input {
  :deep(.el-form-item__content) {
    .el-input--suffix {
      .el-input__wrapper {
        padding: 1px 0px 0px 11px !important;
      }
    }
  }
}
</style>
