<template>
  <div class="follow">
    <div class="screen">
      <div class="btn_w">
        <ElButton type="primary" size="small" @click="() => handleBatch(1)">{{ $t('common.bulk.delay') }}</ElButton>
        <ElButton size="small" @click="() => handleBatch(2)">{{ $t('common.bulk.cancel') }}</ElButton>
      </div>
      <el-pagination
          v-model:current-page="current"
          v-model:page-size="size"
          small="small"
          v-if="total"
          layout="total, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      />
    </div>
    <div class="content">
      <!--            {{ list}}-->
      <ElTable v-loading="loading" :data="list" @selection-change="handleSelectionChange" size="small" :height="'auto'"
               show-overflow-tooltip
               highlight-current-row @current-change="handleCurrentRowChange"
               :cell-style="rowClassName"
               :header-cell-style="headerClassName"
               @cell-mouse-enter="mouseEnters"
               @cell-mouse-leave="mouseLeaves"
      >
        <el-table-column type="selection" width="20"/>
        <el-table-column width="30">
          <template #header>
            <img src="@/assets/email/fj.svg"/>
          </template>
          <template #default="scope">
            <img v-if="scope.row.isExtend === 1" class="pointer" src="@/assets/email/fj.svg"/>
          </template>
        </el-table-column>
        <el-table-column prop="receiveList" :label="$t('common.email.to')">
          <template #default="scope">
            <el-popover
                v-if="(scope.row.customerName != null || scope.row.contactName != null)"
                placement="right"
                :width="'max-content'"
                effect="dark"
                trigger="hover"
                show-after="500"
                :content="scope.row.contactEmail!=null?scope.row.contactEmail:scope.row.customerName+'('+scope.row.companyEmail+')'"
            >
              <template #reference>
                <router-link :to="'/email/customer/detail/' + scope.row.customerId + '?type=1'"
                >
                  <span style="color: #0087ff;"> {{ scope.row.customerName }}</span>
                </router-link>
              </template>
            </el-popover>
            <template v-else>
              <template v-if="scope.row.mailRecipientList.length>1?scope.row.mailRecipientList.length>1:false">
                <el-popover
                    placement="right"
                    :width="'max-content'"
                    effect="dark"
                    trigger="hover"
                    :show-after="500"
                    :content="scope.row.mailRecipientList.join(' , ')"
                >
                  <template #reference>
                    <span style="overflow: hidden">{{ `${scope.row.mailRecipientList[0]} ...` }}</span>
                  </template>
                </el-popover>
              </template>
              <template v-else>
                {{ scope.row.customerName ? scope.row.customerName : scope.row.mailRecipientList[0] }}
              </template>
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="subject" :label="$t('common.subject')"/>
        <el-table-column prop="sendDate" :label="$t('common.email.sendTime')" width="160"/>
        <el-table-column prop="followTime" :label="$t('common.followup.time')" width="160"/>
        <el-table-column prop="remark" :label="$t('common.remark')" width="80">
          <template #default="scope">
            <div class="flex">
              <el-icon :ref="(el) => (refMap[`${scope.row.id}`] = el)"
                       @click.stop="handleRef(refMap[`${scope.row.id}`], scope.row, -1)"
                       :color="scope.row.remark ? '#40a9ff' : '#dddddd'">
                <el-tooltip
                    v-if="emailListCheckoutTarget.remark"
                    class="box-item"
                    :content="emailListCheckoutTarget.remark"
                    placement="right"
                    :show-after="600"
                >
                  <Memo/>
                </el-tooltip>
                <Memo v-else/>
              </el-icon>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('common.cz')" :width="lang==='en'?180:140">
          <template #default="scope">
            <el-button link type="primary" size="small" @click="handleReply(scope.row)"
            >{{ $t('common.followup') }}
            </el-button
            >
            <el-button link type="primary" size="small" @click="handleDelay(scope.row)">{{ $t('common.delay') }}
            </el-button>
            <el-popconfirm
                width="220"
                :confirm-button-text="$t('common.confirm')"
                :cancel-button-text="$t('common.cancel')"
                :icon="QuestionFilled"
                icon-color="#faad14"
                :title="$t('sys.message.ask.followup.cancel')"
                @confirm="handleCancel(scope.row)"
            >
              <template #reference>
                <el-button link type="primary" size="small">{{ $t('common.cancel') }}</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
        <template #empty>
          <el-empty :description="$t('common.data.empty')" :image="emptyUrl"></el-empty>
        </template>
      </ElTable>
    </div>
    <el-dialog
        v-model="dialogVisible"
        :title="$t('common.yst')"
        width="400"
    >
      <el-date-picker
          v-model="time"
          type="date"
          style="width: 200px;margin: auto;display: flex;margin-top: 10px"
          :disabled-date="disabledDate"
          format="YYYY-MM-DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          :placeholder="$t('common.slt')">
      </el-date-picker>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">{{ $t('common.sss16') }}</el-button>
          <el-button type="primary" @click="handleEntry">
            {{ $t('common.confirm') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="tipsVisible">
      <div class="tips_t" style="display: flex; align-items: center;">
        <el-icon size="22" color="#faad14">
          <QuestionFilled/>
        </el-icon>
        <div class="t" style="margin-left: 16px; line-height: 1.4; color: rgba(0,0,0,.85);font-weight: 500;">
          {{ $t('common.plqxsz') }}
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="tipsVisible = false">{{ $t('common.sss16') }}</el-button>
          <el-button type="primary" @click="handleEntryTips">
            {{ $t('common.confirm') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
    <!--组件抽离-->
    <el-popover
        virtual-triggering
        :virtual-ref="tempRef"
        v-model:visible="visiblePopover"
        placement="top"
        :width="204"
        trigger="click"
        show-after="500"
        popper-class="sp_model_popover"
        :popper-options="{
              modifiers: [{
		          name: 'offset',
		          options: {
			          offset: [8, 8]
		          }
	        }]
	    }">
      <div @mouseenter="keepPopoverVisible()" @mouseleave="hidePopover()">
        <el-input v-model="emailListCheckoutTarget.remark" style="width: 180px;"
                  :placeholder="$t('common.email.setBlockSize')" @keydown.enter.native.stop="okPopover"/>
        <div style="text-align: right; margin: 16px 0 0 0;">
          <el-button size="small" text @click.stop="cancelPopover">{{ $t('common.sss16') }}
          </el-button>
          <el-button size="small" type="primary" @click.stop="okPopover"
          >{{ $t('common.confirm') }}
          </el-button
          >
        </div>
      </div>
    </el-popover>
  </div>
</template>
<script>
import {onMounted, ref, computed, watch} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {ElMessage} from 'element-plus';
import {QuestionFilled} from '@element-plus/icons-vue'
import {requestFollowList, requestBatchUpdateFollow, requestUpdateMailRemark} from '@/api/mail';
import {emptyUrl} from '@/utils/statusParams.js'
import {flexWidth} from "@/utils/tableWidth";
import {debouncePlus, throttle} from "@/utils/mylodash"

export default {
  methods: {flexWidth},
  components: {
    QuestionFilled,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const teamId = computed(() => store.state.email.teamID);
    const name = ref('');
    const loading = ref(false);
    const current = ref(1);
    const size = ref(20);
    const total = ref(0);
    const list = ref([]);
    const multipleSelection = ref([]);
    const dialogVisible = ref(false);
    const tipsVisible = ref(false);
    const time = ref('');
// 语言
    const lang = computed(() => store.state.system.langLocal)

    async function getList() {
      loading.value = true;
      const result = await requestFollowList({pageNo: current.value, pageSize: size.value, teamId: teamId.value});
      loading.value = false;
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      const {records, total: counts} = result.result;
      list.value = records;
      total.value = counts;
      console.log('跟进列表', result);
    }

    function handleSelectionChange(rows) {
      console.log('批量勾选', rows);
      multipleSelection.value = rows;
    }

    function handleCurrentRowChange() {
    }

    function handleSizeChange() {
    }

    function handleCurrentChange(page) {
      console.log(page);
      current.value = page;
      getList();
    }

    //取消
    function handleCancel(row) {
      multipleSelection.value = [row];
      time.value = ''
      batch(2);
    }

    //延迟
    function handleDelay(row) {
      multipleSelection.value = [row];
      dialogVisible.value = true;
    }

    //批量操作
    async function batch(type) {
      const result = await requestBatchUpdateFollow({
        type,
        mailFollowDTOList: multipleSelection.value.map(item => ({id: item.id, mailId: item.mailId})),
        time: time.value
      });
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      console.log('批量处理', result);
      await getList();
      // 更新标志
      await store.dispatch('countshow/getTeamAllDataApis')
    }

    //批量操作
    function handleBatch(type) {
      if (!multipleSelection.value.length) {
        ElMessage.error('未选中任何邮件');
        return;
      }
      if (type === 1) {
        dialogVisible.value = true;
      } else {
        tipsVisible.value = true;
      }
    }

    function handleEntry() {
      if (!time.value) {
        ElMessage.warning('请选择时间');
        return;
      }
      dialogVisible.value = false;
      batch(1);
    }

    function handleEntryTips() {
      tipsVisible.value = false;
      time.value = ''
      batch(2);
    }

    function handleReply(row) {
      console.log(row, 'rows');
      localStorage.setItem('follow', JSON.stringify(row))
      const query = {id: row.mailId, flag: row.flag, type: "follow"};
      if (row.customerId) {
        query.customerId = row.customerId;
      }
      router.push({
        path: '/email/write_mail',
        query,
      });
      //   取消目标 邮件跟进
      let timer = setTimeout(() => {
        handleCancel(row)
        clearTimeout(timer)
      }, 300)
    }

    function disabledDate(date) {
      return date.getTime() < Date.now();
    }

    watch(() => teamId.value, () => {
      console.log('组件团队ID 监听', teamId.value);
      getList();
    });
    onMounted(() => {
      if (teamId.value) {
        getList();
      }
    });


    const rowClassName = ({row, column, rowIndex, columnIndex}) => {
      console.log(row, columnIndex, '333')
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3 || columnIndex === 7) {
        return {textAlign: 'left'}
      } else {
        return {textAlign: 'center'}
      }
    }

    const headerClassName = ({row, column, rowIndex, columnIndex}) => {
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 2 || columnIndex === 3 || columnIndex === 7) {
        return {'text-align': 'left',}
      } else {
        return {'text-align': 'center'}
      }
    }

    const sidebarTargetNumber = computed(() => store.state.countshow.sidebarTargetNumber)
    watch(() => sidebarTargetNumber.value, async () => {
      await getList()
    })

    // 搜索
    const handleSearch = () => {

    }


    // remark
    const refMap = ref([])
    const tempRef = ref(null)
    const visiblePopover = ref(false)
    const emailListCheckoutTarget = ref({remark: ''})
    const handleRef = debouncePlus((ref, item, type) => {
      tempRef.value = ref
      visiblePopover.value = true;
      emailListCheckoutTarget.value = item;
    }, 300)


    // ---------------
    const hidePopoverTimeout = ref(false)
    // 这里是开始点
    const mouseEnters = debouncePlus((row) => {
      emailListCheckoutTarget.value = row;
    }, 200)
    const mouseLeaves = () => {
      hidePopoverTimeout.value = setTimeout(() => {
        visiblePopover.value = false;
      }, 300);
    }
    const keepPopoverVisible = () => {
      if (hidePopoverTimeout.value) {
        clearTimeout(hidePopoverTimeout.value);
        hidePopoverTimeout.value = null;
      }
      visiblePopover.value = true;
    }
    const hidePopover = () => {
      visiblePopover.value = false;
    }
    const cancelPopover = () => {
      visiblePopover.value = false;
      emailListCheckoutTarget.value.remark = ''
    }
    // -----------------
    const okPopover = () => {
      setRemark(emailListCheckoutTarget.value.mailId, emailListCheckoutTarget.value.remark)
      emailListCheckoutTarget.value = {};
      visiblePopover.value = false;
    }

    const activeItems = ref({})

    async function setRemark(id, val) {
      // console.log(id, val, 'ff')
      if (val.length > 30) {
        ElMessage.warning('30个字以内')
        activeItems.value.remark = "";
        return;
      } else {
        const result = await requestUpdateMailRemark({id, remark: val});
        if (result.code !== 200) {
          ElMessage.error(result.message);
          return;
        }
      }
    }


    return {
      hidePopover,
      keepPopoverVisible,
      cancelPopover,
      okPopover,
      tempRef,
      visiblePopover,
      lang,
      handleRef,
      emailListCheckoutTarget,
      mouseEnters,
      mouseLeaves,
      refMap,
      handleSearch,
      rowClassName,
      headerClassName,
      name,
      current,
      size,
      list,
      total,
      loading,
      dialogVisible,
      tipsVisible,
      time,
      handleEntry,
      handleReply,
      handleEntryTips,
      disabledDate,
      handleBatch,
      handleSizeChange,
      handleCurrentChange,
      handleCurrentRowChange,
      handleSelectionChange,
      handleCancel,
      handleDelay,
      emptyUrl
    }
  }
}
</script>
<style lang="less" scoped>
//@import "../../less/page";

.follow {
  padding: 16px;

  .screen {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .input-with-select {
    width: 350px;
  }

  .content {
    background-color: #fff;
    padding-bottom: 6px;

    .title {
      margin-bottom: 10px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 21px;
      font-weight: 500;
    }

    .pagination {
      display: flex;
      justify-content: flex-end;
      margin: 16px 0;
    }
  }
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: -20px;
}

.sp_model_popover {
  padding: 0px !important;

  & > div {
    margin: -12px !important;
    padding: 12px !important;
  }
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
