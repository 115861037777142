<template>
  <Cards>
    <TopSearch :adminShow="true" :searchShow="true" @searchKey="getSearchKey"/>
  </Cards>
  <Cards>
    <div>
      <div style="position: relative">
        <div class="top_tips">
          <div><h2 class="h2_titles">{{ $t("common.customer.list") }}</h2></div>
          <div>
            <el-button
                :disabled="totals===0"
                type="danger"
                :icon="Delete"
                @click="handleClickDelete"
                v-if="isSuperAdmin"
            >{{ $t('common.delete') }}
            </el-button
            >
            <el-button type="primary" :icon="Plus" @click="toDetails('Create')">{{
                $t("common.create")
              }}
            </el-button>
            <!--          批量导入-->
            <el-button type="primary" :icon="DocumentAdd" @click="fileImport">
              {{ $t('list.customer.location.import') }}
            </el-button>
            <!--          批量导出-->
            <el-button :disabled="totals===0" type="primary" :icon="Upload" @click="fileExport" v-if="isSuperAdmin">
              {{ $t('list.customer.export.user') }}
            </el-button>
          </div>
        </div>
        <Tables
            :totals="totals"
            ref="targetTable"
            :table-data="customerList"
            :table-colum-titles="table_columns_list"
            :is-show-pages="false"
            :isShowDrawer="true"
            :isShowEdit="true"
            :isLoading="isLoading"
            @selections="handleSelectionChange"
            @getSelect="getSelectValue"
            @selectionChange="getSelectChange"
        >
          <template #linkUserHistory>
            <div class="settings">
              <el-button type="primary" link>{{ $t("common.contact.record") }}</el-button>
            </div>
          </template>
        </Tables>
      </div>
    </div>
    <div class="pages">
      <el-pagination
          popper-class="page"
          v-model:current-page="current"
          v-model:page-size="size"
          :page-sizes="[10,30,50]"
          background
          layout="sizes,total, prev, pager, next"
          v-if="totals"
          :total="totals"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      />
    </div>
  </Cards>
  <CustomerExcelImport v-if="visitable" v-model="visitable" @close="close"
                       @getCustomerList="getCustomerList"></CustomerExcelImport>

  <DeleteCustomer v-if="dialogVisiblePopover" v-model="dialogVisiblePopover" @close="closeDialog"
                  :multipleSelection="multipleSelection" :getCustomerList="getCustomerList"
                  :isSelectAll="isSelectAll" :searchValue="searchValue"></DeleteCustomer>

</template>
<script setup>
import {ref, computed, watch} from "vue";
import {useRouter} from "vue-router";
import {Delete, DocumentAdd, Plus, Upload} from "@element-plus/icons-vue";
import Cards from "./Cards.vue";
import TopSearch from "./TopComponents/TopSearch.vue";
import Tables from "./Tables/Tables.vue";
import {
  exportCustomerData,
  getMailCustomerList,
} from "@/api/customer";
import {useStore} from "vuex";
import {table_columns_list} from './map'
import {ElMessage} from "element-plus";
import CustomerExcelImport from "@/components/DiaPops/CustomerExcelImport.vue";
import {throttle} from "@/utils/mylodash"
import {i18n} from "@/lang";
import DeleteCustomer from "@/components/DiaPops/DeleteCustomer.vue";


const store = useStore()
// 权限控制
const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])

const teamId = computed(() => store.state.email.teamID);
// 搜索
const searchValue = ref({});
const getSearchKey = async (value) => {
  const newObj = JSON.parse(JSON.stringify(value));
  searchValue.value = newObj;
  console.log(searchValue.value, 'searchForm.value.managerId')
  // 列表数据
  await getCustomerList();
  isSelectAll.value ? targetTable.value.checkAllItem() : targetTable.value.unCheckAllItem()
};

const router = useRouter();
const multipleSelection = ref([]);
const isLoading = ref(false);

// table 选中的数组
const handleSelectionChange = (val) => {
  console.log(val, 'val')
  multipleSelection.value = val;
};

const isSelectAll = ref(false)
const getSelectValue = (val) => {
  isSelectAll.value = val;
  val ? targetTable.value.checkAllItem() : targetTable.value.unCheckAllItem()
}
const getSelectChange = (val) => {
  isSelectAll.value = val;
}


// 删除按钮
const dialogVisiblePopover = ref(false)
const handleClickDelete = throttle(() => {
  if (multipleSelection.value.length === 0 && !isSelectAll.value) {
    ElMessage.warning(i18n.global.t('common.email.chooseCustomer'));
    return;
  }
  dialogVisiblePopover.value = true;
}, 2000)

const closeDialog = () => {
  targetTable.value.setIsSelectAll(false)
  targetTable.value.unCheckAllItem()
  dialogVisiblePopover.value = false;
}
/**
 * 新建客户
 * @param name
 * @param id
 * @returns {Promise<void>}
 */
const toDetails = async (name, id) => {
  let newUrl = router.resolve({
    name,
    params: {id},
    query: {contact: 1}
  });
  await store.commit('customer/setRuleForm', {
    zoneCode: "",
    customerCode: "",
    zoneAddress: "",
    customerName: "",
    setUpTime: "",
    economicNature: "",
    companyWebsite: "",
    companyAddress: "",
    phoneWayList: [],
    emailWayList: [],
    companyFax: "",
    companyRemark: "",
    customerLevel: "",
    customerType: "",
    customerTypeId: "",
    product: "",
    marketingRemark: " ",
    createBy: "",
    branchAdmin: "",
    mangerVOList: [{managerName: ''}]
  })
  await store.commit('customer/setForm', {
    phones: [{phoneNumber: "", isDefault: true}],
    emails: [{email: "", isDefault: true}],
  })
  openNewWindow(newUrl.href);
};

const openNewWindow = (url) => {
  window.open(url, "_self");
};

// 分页
const current = ref(1);
const size = ref(10);
const totals = ref(0);
const targetTable = ref(null)

async function handleCurrentChange(page) {
  current.value = page;
  console.log(isSelectAll.value, 'isSelectAll.value')
  await getCustomerList();
  isSelectAll.value ? targetTable.value.checkAllItem() : targetTable.value.unCheckAllItem()
}

async function handleSizeChange(pageSize) {
  size.value = pageSize;
  await getCustomerList();
  isSelectAll.value ? targetTable.value.checkAllItem() : targetTable.value.unCheckAllItem()
}

// 全部分管客户列表数据
const customerList = ref([]);
const userInfo = computed(() => store.state.user.userInfo)
/**
 * 获取客户列表
 * @returns {Promise<void>}
 */
const getCustomerList = async () => {
  isLoading.value = true;
  // const managerIdLocalStorage = JSON.parse(window.localStorage.getItem('userInfo')).id
  const {
    targetTime,
  } = searchValue.value
  const startTime = targetTime[0] ? `${targetTime[0]} 00:00:00` : null;
  const endTime = targetTime[1] ? `${targetTime[1]} 23:59:59` : null;
  if (searchValue.value.managerId === "all") {
    searchValue.value.managerId = ""
  }
  await getMailCustomerList({
    ...searchValue.value,
    startTime,
    endTime,
    pageSize: size.value,
    pageNo: current.value,
    teamId: teamId.value,
    type: 1,
  }).then((res) => {
    const {records, size, total, pages} = res.result;
    // 添加一条假的数据——————目的是为了 添加 全选按钮
    customerList.value = records;
    totals.value = total - 0;
    isLoading.value = false;
    multipleSelection.value = []
  });
};

watch(() => teamId.value, async () => {
  getCustomerList();
  await store.dispatch("groups/getUserList");
})

/**
 * 客户的批量导入
 */
const visitable = ref(false);
const fileImport = async (event) => {
  visitable.value = true;
}
const close = () => {
  visitable.value = false;
}


/**
 * 客户的批量导出
 */
const fileExport = throttle(async () => {
//   是否有选中的项？
  const params = {
    customerIdList: [],
    teamId: teamId.value
  }
  console.log(multipleSelection.value.length === 0, isSelectAll.value, 'show')
  if (multipleSelection.value.length === 0 || isSelectAll.value) {
    //   否则全部导出
    delete params.customerIdList
    params.userId = searchValue.value.managerId
  } else {
    //   有选中的项 就只导出选中的项
    params.customerIdList = multipleSelection.value.map(_ => _.id)
  }
  await exportCustomerData(params, {
    responseType: "blob",
  }).then(res => {
    console.log(res, 'res')
    const link = document.createElement('a');
    const blob = new Blob([res], {type: 'application/vnd.ms-excel;charset=utf-8'}); // 设置正确的字符编码
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', '客户信息');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}, 1000);


</script>

<style lang="less" scoped>
@import "../../less/page.less";
@import "../../less/resetFul.less";

.table_name {
  font-size: 20px;
}

.top_tips {
  display: flex;
  justify-content: space-between;
}

.settings {
  display: flex;
  justify-content: center;
}

:deep(.el-table__cell) {
  text-align: center;
}

:deep(.el-checkbox__inner::after) {
  top: 1px !important;
  left: 4px !important;
}

.affix-container {
  text-align: center;
  height: 400px;
  border-radius: 4px;
  background: var(--el-color-primary-light-9);
}

.pages {
  margin-top: 20px !important;
}

.pages :deep(.el-pagination .el-select) {
  width: 100px !important
}

</style>
