<template>
  <div id="register">
    <div class="signUpCard">
      <img src="@/assets/logo.png" class="logoImg" @click="$router.push('/login')"/>
      <div class="content">
        <ElForm label-position="top">
          <div class="item">
            <ElFormItem :label="$t('common.cds45')">
              <div class="inputBar">
                <el-input v-model="form.alias" maxlength="10" :placeholder="$t('common.cds47')" @blur="validateInput"/>
                <div class="text_inner">
                  <span style="margin-top: 8px">该信息将用于后台登录，不可修改，请认真填写。</span>
                  <span>可基于公司名称或品牌名称填写，体现特点，比如Baidu、Tencent 等。</span>
                  <span>公司账户只能输入字母、数字、下划线，且以字母开头，最长不超过10位。</span>
                </div>
              </div>
            </ElFormItem>
            <ElFormItem :label="$t('common.cds46')" style="margin-top: 124px">
              <el-input v-model="form.name" :placeholder="$t('sys.tip.input.enterprise.name')"/>
            </ElFormItem>
            <ElFormItem :label="$t('common.label.location')">
              <ElSelect v-model="form.address" :placeholder="$t('common.country')"
                        filterable class="select_country" style="width: 100%;">
                <ElOption
                    v-for="item in areaList"
                    :key="item.country"
                    :label="item.country"
                    :value="item.country"
                />
              </ElSelect>
            </ElFormItem>
          </div>
          <div class="item">
            <ElFormItem :label="$t('common.cjzzh')" prop="account">
              <el-input v-model="form.account" maxlength="10" :placeholder="$t('common.cjzzhp')"
                        @blur="validateInputAccount"></el-input>
              <span style="margin-top: 8px">{{ $t('common.ydh222') }}</span>
              <span>{{ $t('common.ydh') }}</span>
            </ElFormItem>
            <ElFormItem prop="phone" :label="$t('common.cjzsjh')" style="flex:1;">
              <div class="flex">
                <div class="flex-center pointer flagareaC">
                  <CountrySelect @changeSelect="getSelectValue"></CountrySelect>
                </div>
                <el-input type="number" v-model="form.phone" style="width:100%; height: 40px"
                          :placeholder="$t('sys.tip.input.phone')"></el-input>
              </div>
            </ElFormItem>
            <ElFormItem prop="code">
              <el-input v-model="form.code" type="number" :placeholder="$t('sys.tip.input.sms.code')"></el-input>
              <div class="wrapper" ref="wrapperRef">
                <div class="btn" ref="btnRef">
                  <el-icon v-if="!sendFlag">
                    <DArrowRight/>
                  </el-icon>
                  <el-icon v-else color="#67C23A">
                    <SuccessFilled/>
                  </el-icon>
                </div>
                <p class="text" ref="textRef">请向右滑动发送短信验证码</p>
                <div class="bg" ref="bgRef"></div>
              </div>
            </ElFormItem>
            <ElFormItem :label="$t('common.password')" prop="password">
              <el-input type="password" show-password v-model="form.password"
                        :placeholder="$t('sys.tip.input.password')" style="height:40px;"
                        @blur="passwordvalidate"></el-input>
              <span style="margin-top: 20px;">{{ $t('sys.message.error.password') }}</span>
            </ElFormItem>
          </div>
        </ElForm>
      </div>
      <div class="nextBar">
        <div v-if="isWriteAll" class="nextbtn pointer" style="width:340px" @click="changeCurrent">
          {{ $t('common.agreejoin') }}
        </div>
        <div v-else class="nextbtn" style="width:340px;background-color: #aaaaaa;cursor: not-allowed">
          {{ $t('common.agreejoin') }}
        </div>
        <span class="nextText1">{{ $t('sys.reg.s4') }}</span>
        <span class="nextText2">
          <a class="edit" :href="isLangCN ? '/doc/TermsofService-cn.html' : '/doc/TermsofService.html'" target="_blank">{{
              $t('sys.reg.s8')
            }}</a>
          <span style="margin:0 2px">{{ $t('sys.reg.s5') }}</span>
          <a class="edit" :href="isLangCN ? '/doc/PrivacyPolicy-cn.html' : '/doc/PrivacyPolicy.html'"
             target="_blank">{{ $t('sys.reg.s9') }}</a>
          <!-- <span>{{ _store('lang') == 'en' ? '.' : '。' }}</span> -->
          <!-- 底部信息栏 -->
    <Footers></Footers>
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import {computed, onMounted, reactive, ref,} from 'vue';
import {ElMessage} from 'element-plus';
import {sendRegisterSms, registerCompany,} from '../api/index';
import CountrySelect from "@/components/CountrySelect/index.vue";
import {useRouter} from "vue-router";
import Footers from '@/components/Footer'
import {accountNameReg, passwordReg} from "@/utils/statusParams";
import sessionStorageUtil from "@/utils/sessionStorageUtil";

const router = useRouter();
const store = useStore()
const i18n = useI18n();
const areaList = computed(() => store.state.customer.areaList);
const wrapperRef = ref(null);
const btnRef = ref(null);
const textRef = ref(null);
const bgRef = ref(null);
const waitTime = ref(61);
const sendFlag = ref(false);
const form = reactive({
  account: '',
  alias: '',
  code: '',
  name: '',
  password: '',
  phone: '',
  phoneCode: '86',
  address: ''
});
const isLangCN = computed(() => {
  return navigator.language === 'zh-CN';
});
const currentCountry = reactive({
  countryEn: "China", country: "中国", prefix: "86", icon: "CN.png", id: "43", zoneCode: "CN"
});
const isWriteAll = computed(() => {
  return Object.values(form).every(item => item !== '');
});

const getSelectValue = (val) => {
  Object.assign(currentCountry, val)
  console.log(val, currentCountry)
}


async function changeCurrent() {
  console.log(form, 'fff')
  if (isOkAlias.value && isOkAccount.value && isOkPassword) {
    const result = await registerCompany({...form});
    if (result.code !== 200) {
      ElMessage.error(result.message);
      return
    }
    ElMessage.success(result.message);
    router.push('/login')
  } else {
    if (!isOkAccount) {
      form.account = ""
      ElMessage.warning("创建者账户只能输入字母、数字、下划线，且以字母开头，最长不超过10位。")
    } else if (!isOkAlias) {
      form.alias = ""
      ElMessage.warning("公司账户只能输入字母、数字、下划线，且以字母开头，最长不超过10位。")
    } else if (!isOkPassword) {
      form.password = ""
      ElMessage.warning(i18n.t('common.password.text'))
    }
  }
}

function initSlider() {
  var wrapper = wrapperRef.value;
  var btn = btnRef.value;
  var bg = bgRef.value;
  var text = textRef.value;
  var left = null;
  btn.onmousedown = function (event) {
    console.log(event.clientX);
    if (!form.phone) {
      ElMessage.error('请先输入手机号');
      return;
    }
    var downX = event.clientX;      //触发事件时 鼠标相对于浏览器的x距离
    document.onmousemove = function (e) {
      var moveX = e.clientX;
      console.log(moveX);        //触发事件时 鼠标相对于浏览器的x距离

      left = moveX - downX;      //点击down移动move 滑动的距离

      if (left < 0) {
        left = 0
      }
      if (left > wrapper.offsetWidth - btn.offsetWidth) {      //还需要减去btn的宽度
        left = wrapper.offsetWidth - btn.offsetWidth;
      }
      btn.style.left = left + 'px';
      bg.style.width = left + 'px';

      if (left >= wrapper.offsetWidth - btn.offsetWidth) {
        console.log(left, wrapper.offsetWidth, btn.offsetWidth);
        //ajax请求 判断是否可以登录成功
        wrapper.style.color = 'white';
        btn.onmousedown = null;    //防止 btn再次被点击
        document.onmousemove = null;
        document.onmouseup = null;
        sendRegisterSms({
          phone: form.phone, phoneCode: currentCountry.prefix, type: 2
        }).then(result => {
          ElMessage.success('发送成功');
          waitTime.value--;
          text.innerText = i18n.t('displace.smscode.reacquire', {number: waitTime.value})
          sendFlag.value = true;
          const timer = setInterval(() => {
            if (waitTime.value > 1) {
              waitTime.value--
              text.innerText = i18n.t('displace.smscode.reacquire', {number: waitTime.value})
            } else {
              sendFlag.value = false;
              btn.style.left = 0;
              bg.style.width = 0;
              wrapper.style.color = 'black';
              clearInterval(timer)
              text.innerText = '请向右滑动发送短信验证码'
              waitTime.value = 61;
              initSlider();
            }
          }, 1000)
        })
      }
    }
  }

  // btn.onmousedown    //不能只监听滑块上的鼠标抬起; 在整个文档上鼠标抬起体验更好
  document.onmouseup = function (event) {
    document.onmousemove = null;
    if (left < wrapper.offsetWidth - btn.offsetWidth) {
      btn.style.left = 0;
      bg.style.width = 0;
      wrapper.style.color = 'black';
    }
    console.log('鼠标抬起' + event.clientX)
    document.onmouseup = null;
  }
}

onMounted(async () => {
  await store.dispatch('customer/getAreaNames')
  initSlider();
})

const isOkAlias = ref(false);
const isOkAccount = ref(false);
const isOkPassword = ref(false);

const validateInput = (event) => {
  const value = event.target.value
  if (accountNameReg.test(value)) {
    form.alias = value;
    isOkAlias.value = true;
  } else {
    form.alias = ""
    ElMessage.warning("公司账户只能输入字母、数字、下划线，且以字母开头，最长不超过10位。")
  }
}

const validateInputAccount = (event) => {
  const value = event.target.value
  if (accountNameReg.test(value)) {
    form.account = value;
    isOkAccount.value = true;
  } else {
    form.account = ""
    ElMessage.warning("创建者账户只能输入字母、数字、下划线，且以字母开头，最长不超过10位。")
  }
}


const passwordvalidate = (event) => {
  const value = event.target.value
  if (passwordReg.test(value)) {
    form.password = value;
    isOkPassword.value = true
  } else {
    form.password = ""
    ElMessage.warning(i18n.t('common.password.text'))
  }
}

</script>
<style lang="less" scoped>
.footerInfoBar {
  color: rgba(0, 0, 0, .65);
  margin: 30px auto;
}

.edit {
  color: #1890ff;
  cursor: pointer;
}

.wrapper {
  position: relative;
  width: 340px;
  height: 34px;
  line-height: 34px;
  background-color: #f5f5f5;
  border: 1px solid var(--el-input-border-color, var(--el-border-color));
  margin: 16px auto 0;
  border-radius: 4px;

  .bg {
    position: absolute;
    height: 100%;
    width: 0;
    background: #7ac23c;
    z-index: 1;
  }

  .text {
    position: absolute;
    width: 100%;
    margin: 0;
    font-size: 14px;
    text-align: center;
    z-index: 2;
  }

  .btn {
    position: absolute;
    top: 0;
    width: 40px;
    height: 32px;
    z-index: 3;
    // border: 1px solid #ccc;
    background: #fff center no-repeat;
    /***btn背景图片***/
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
}

.nextBar {
  margin: 0px auto 30px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .nextbtn {
    width: 340px;
    height: 50px;
    line-height: 50px;
    background: #d93737;
    border-radius: 25px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 1px;
    color: #ffffff;
  }

  .nextText1 {
    margin-top: 8px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    color: #333333;
    line-height: 21px;
    letter-spacing: 1px;
  }

  .nextText2 {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 1px;
  }
}

.el-input :deep(.el-input__wrapper) {
  background-color: #f5f5f5 !important;
  padding: 0;

  .el-input__inner {
    padding: 0 11px;
  }
}

#register {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .signUpCard {
    position: relative;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
    border-radius: 9px;
    width: 820px;
    margin-top: 60px;

    .logoImg {
      position: absolute;
      width: 218px;
      height: 42px;
      left: 0;
      top: -50px;
    }

    .content {
      width: 100%;
      margin-top: 30px;

      .el-form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 50px;
      }

      .item {
        width: 340px;
        flex-shrink: 0;
        flex-grow: 0;

        :deep(.select_country .el-input__wrapper) {
          background-color: #f5f5f5 !important;
        }
      }
    }
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.item {
  span {
    display: block;
    width: 340px;
    font-size: 14px;

    color: #aaaaaa;
    line-height: 21px;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mr-10 {
  margin-right: 10px;
}

.pointer {
  cursor: pointer;
}

.p-10 {
  padding: 10px;
}

.wrapClass {
  max-height: 70vh;
}

.flagareaC {
  font-size: 14px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputBar {
  position: relative;
  width: 340px;
  height: 50px;
}

.wrapClass {
  max-height: 70vh;
}

:deep(.el-input__wrapper) {
  height: 52px !important;
}

:deep(.el-input__inner) {
  height: 52px !important;
  width: 100% !important;
  line-height: 52px !important;
}

:deep(.el-input__suffix-inner) {
  margin-right: 10px;
}

</style>
